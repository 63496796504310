import Cookie from 'js-cookie'

console.log(window.siteEnv);

(function () {
    function getTopLevelDomain() {
        const hostname = window.location.hostname;  // Get the current domain
        const parts = hostname.split('.');          // Split the hostname into parts based on the "."

        // If it's a long domain , get the last 3 parts
        // If it's a shorter domain, get the last 2 parts
        if (parts.length > 2) {
            return '.' + parts.slice(-3).join('.');  // Returns the last 3 parts
        } else {
            return '.' + parts.slice(-2).join('.');  // Returns the last 2 parts
        }
    }

    // Check if UID exists


    const uuid = Cookie.get('uuid') || localStorage.getItem('uuid');
    console.log('track get uuid cookie', Cookie.get('uuid'))
    console.log('track get uuid ls', localStorage.getItem('uuid'))
    if (!Cookie.get('uuid') && localStorage.getItem('uuid')) {
        console.log('track set uuid')
        Cookie.set('uuid', localStorage.getItem('uuid'), {
            httpOnly: false,
            expires: 367,
            secure: true,
            sameSite: 'Lax',
            domain: `${getTopLevelDomain()}`
        })

    }
    if (localStorage.getItem('userIdentifier')) {
        console.log('track set userIdentifier')
        Cookie.set('ga_client_id', localStorage.getItem('userIdentifier'), {
            httpOnly: false,
            expires: 367,
            secure: true,
            sameSite: 'Lax',
            domain: `${getTopLevelDomain()}`
        })
    }

    if (!uuid) {
        // Assume this is the variable you're working with
        let trackUrl = 'https://' + window.siteEnv.TRACKING_DOMAIN + '/get-uuid';

        fetch(trackUrl, {
            credentials: 'include',
            method: 'GET'
        })
            .then(response => response.json())
            .then(data => {
                // Set UUID in the cookie
                console.log("UUID set:", data.uuid, getTopLevelDomain());
                //document.cookie = `uuid=${data.uuid}; path=/; domain=${getTopLevelDomain()}; max-age=${60 * 60 * 24 * 365}; Secure; SameSite=Lax`;
                Cookie.set('uuid', data.uuid, {
                    httpOnly: false,
                    expires: 367,
                    secure: true,
                    sameSite: 'Lax',
                    domain: `${getTopLevelDomain()}`
                })
            })
            .catch(err => {
                console.error('Error fetching UUID:', err);
            });
    } else {

        console.log("UUID already exists:", uuid);
    }
})();


window.googlefc = window.googlefc || {};
window.googlefc.ccpa = window.googlefc.ccpa || {}
window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];

const cookieSettingsLink = document.querySelector('.cc-settings');

if (cookieSettingsLink) {
    cookieSettingsLink.addEventListener('click', function (event) {
        console.log('cookie settings')
        event.preventDefault(); // Prevent the default action (navigation) if needed
        if (googlefc && googlefc.callbackQueue) {
            // Use a regular function instead of an arrow function
            googlefc.callbackQueue.push({
                'CONSENT_DATA_READY': function () {
                    googlefc.showRevocationMessage();
                }
            });
        } else {
            console.error('googlefc is not defined or callbackQueue is missing.');
        }
    });
} else {
    console.error('Element with class "cc-settings" not found.');
}


function getConsentData() {
    // Check if google_tag_data is available
    if (window.google_tag_data && window.google_tag_data.ics) {
        const consentEntries = window.google_tag_data.ics.entries;

        // Log the consent entries to the console
        console.log('Consent Entries:', consentEntries);
        console.log('Type of consentEntries:', typeof consentEntries);

        // Check if consentEntries is an array
        if (Array.isArray(consentEntries)) {
            // If it's an array, iterate through the entries
            consentEntries.forEach(entry => {
                console.log('Entry:', entry);
                // Access specific properties, e.g., type and value
                console.log('Type:', entry.type);
                console.log('Value:', entry.value);
            });
        } else {
            console.log('Consent entries is not an array:', consentEntries);
        }
    } else {
        console.log('No consent data available.');
    }
}

// Example of calling the function
// getConsentData();

// Function to get consent state for all categories
function getAllConsentStatuses() {
    if (window.gtag) {
        // Get consent status for all categories
        window.gtag('consent', 'get', function (consentData) {
            console.log('Consent Data: ', consentData);  // Logs all consent categories and their statuses
        });
    } else {
        console.log('Google Tag is not initialized');
    }
}

// Call the function to get all consent states
// getAllConsentStatuses();

// Queue the callback using the CONSENT_DATA_READY key on the callbackQueue.
window.googlefc.callbackQueue.push({
    'CONSENT_DATA_READY':
        () => __tcfapi('addEventListener', 2.2, (data, success) => {
            // Do something with consent data value; this callback may be invoked
            // multiple times if user consent selections change.

            if (data.eventStatus && data.eventStatus == "useractioncomplete") {
                console.log('useractioncomplete', data)
                const consentValues = getConsentValues(data);
                console.log('Consent Values:', consentValues);
                updateGtagWithConsent(consentValues);
                getAllConsentStatuses();
            }
        })
});


// Example of retrieving consent values based on the IAB TCF framework
function getConsentValues(consentData) {
    // Initialize an object to hold the consent values
    const consentValues = {
        ad_storage: false,
        analytics_storage: false,
        ad_user_data: false,
        ad_personalization: false,
        functionality_storage: true,
        personalization_storage: false,
    };

    // Check and set values based on consent data
    if (consentData && consentData.purpose && consentData.purpose.consents) {
        const purposes = consentData.purpose.consents;

        // Check ad_storage consent (purposes 1, 2, 3, 4)
        consentValues.ad_storage = purposes[1] && purposes[2] && purposes[3] && purposes[4];

        // Check analytics_storage consent (purposes 1, 7, 8)
        consentValues.analytics_storage = purposes[1] && purposes[7] && purposes[8];

        // Check ad_user_data consent (purposes 1, 3, 5)
        consentValues.ad_user_data = purposes[1] && purposes[3] && purposes[5];

        // Check ad_personalization consent (purposes 4, 5, 6)
        consentValues.ad_personalization = purposes[4] && purposes[5] && purposes[6];

        // Check functionality_storage consent (purpose 1)
        // consentValues.functionality_storage = purposes[1];

        // Check personalization_storage consent (purposes 3, 5, 6)
        consentValues.personalization_storage = purposes[3] && purposes[5] && purposes[6];
    }

    return consentValues;
}

// Function to update gtag values based on consent
function updateGtagWithConsent(consentValues, reinitGa4 = false) {
    // Check and set gtag parameters based on consent values
// Log for debugging
    console.log('Updated gtag consent:', {
        ad_storage: consentValues.ad_storage ? 'granted' : 'denied',
        analytics_storage: consentValues.analytics_storage ? 'granted' : 'denied',
        ad_user_data: consentValues.ad_user_data ? 'granted' : 'denied',
        ad_personalization: consentValues.ad_personalization ? 'granted' : 'denied',
    });
    try {
        gtag('consent', 'update', {
            'ad_storage': consentValues.ad_storage ? 'granted' : 'denied',          // Map to granted/denied
            'analytics_storage': consentValues.analytics_storage ? 'granted' : 'denied', // Map to granted/denied
            'ad_user_data': consentValues.ad_user_data ? 'granted' : 'denied',          // Map to granted/denied
            'ad_personalization': consentValues.ad_personalization ? 'granted' : 'denied', // Map to granted/denied
            'functionality_storage': consentValues.functionality_storage ? 'granted' : 'denied', // Map to granted/denied
            // Note: functionality_storage usually does not need explicit mapping, but true as default
            // You can manage it in your implementation as needed
        });
        // Optional: Re-initialize Google Analytics if necessary
        // if (reinitGa4) {
        //     gtag('config', 'GA_MEASUREMENT_ID'); // Uncomment and replace with your GA Measurement ID
        // }
        console.log('Consent status updated successfully');
    } catch (error) {
        console.error('Error occurred while updating consent status:', error);
    }
}
